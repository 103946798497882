<template>
	<div id="app">
		<router-view />

	</div>
</template>
<script>
	export default {
		created() {
			this.getWebsiteInfo();
			this.getMenuList();
			this.getPayType();
			this.getUserAvatar()
			this.getFooterInfo()
			this.getNavColor()
		},
		methods: {
			// 获取站点信息
			getWebsiteInfo() {
				this.$http.post('userWebsite').then(res => {
					if (res.code == 200) {
						this.$store.commit('SET_WEBSITE', res.data)
						document.title = res.data.name
					}
				})
			},
			//获取底部信息数据
			getFooterInfo() {
				this.$http.post('querySysBottom').then(res => {
					if (res.code == 200) {
						this.$store.commit('SET_FOOTER', res.data)
					}
				})
			},
			// 获取站点导航栏
			getMenuList() {
				this.$http.post('getMenuList', {
					type: 1
				}).then(res => {
					let name = '新闻公告'
					if (res.code == 200) {
						this.$store.commit('SET_MENU', res.data)
						 res.data.forEach(item => {
							if (item.content === 2) {
								name = item.name
							}
						})
					}
					localStorage.setItem('noticeTitle', name)
				})
			},
			//获取导航栏颜色
			async getNavColor() {
				await this.$http.post('getNavColor').then(res => {
					if (res.code === 200) {

						this.$store.commit('SET_COLOR', res.data)
					}
				})
			},
			// 获取站点支付方式
			getPayType() {
				this.$http.post('getPayType').then(res => {
					if (res.code == 200) {
						// console.log(res);
						let datas = []
						res.data.map(item => {
							if (!item.isEmploy) {
								datas.push(item)
							}
						})
						let data = datas.map(item => {

							let name = '';
							switch (item.type) {
								case 1:
									name = '微信';
									break;
								case 2:
									name = '支付宝'
									break;
								case 3:
									name = '银联'
									break;
							}
							return {
								type: item.type,
								name
							}
						})

						this.$store.commit('SET_PAYBY', data)
					}
				})
			},
			// 获取用户头像
			getUserAvatar() {
				this.$http.post('queryLoginPhoto').then(res => {
					if (res.code == 200) {
						this.$store.commit('SET_AVATAR', res.data)
					}
				})
			}
		}
	}
</script>
<style lang="less">
	#app,
	body {
		height: 100%;
		width: 100%;
	}

	.pageContainer {
		display: flex;
		flex-direction: column;
		min-height: 100vh;

		.content {
			flex: 1 1 auto;
		}
	}
</style>
